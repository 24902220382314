import * as config from 'config';
import React, { useEffect, useState } from 'react'
import BaseLayout from 'blocks/layout/base-layout'
import { postData } from 'helpers/post-data'
import {
  Button,
  Form,
  Heading,
  Section,
  TOSLinks,
  EmailField,
  Icon
} from 'components'
import { NavigationTarget } from 'data'
import { builder } from 'routes'
import { useRedirect, useAppWideState, useFormField } from 'hooks'
import googleSignupEmailPng from 'assets/images/google-signup.png'
import { gapi } from 'gapi-script'

import { readError } from 'helpers'

import { GoogleLogin, GoogleOAuthProvider} from '@react-oauth/google'
import { jwtDecode } from 'jwt-decode'

const CLIENT_ID = config.REACT_APP_GOOGLE_CLIENT_ID;
const SCOPES = config.REACT_APP_GOOGLE_SCOPES;
const DISCOVERY_DOCS = [config.REACT_APP_GOOGLE_DISCOVERY_DOCS];

const GoogleSignupEmail: React.FC = () => {
  const redirect = useRedirect()
  const { isSignedIn, signIn, showError } = useAppWideState()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const url = builder.group.checkEmail

  const email = useFormField('')

  const trackEvent = (n: string) => {
    postData('/api/analytics/track', { funnel: "GROUP_CREATE", event: n, url: window.location.href })
    return false
  }

  useEffect(() => {
    trackEvent("APP_GC_CONFIRM_EMAIL")
  }, [])

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsSubmitting(true)

    try {
      const res = await postData('/api/unregistered/confirm/email/send', {
        email
      })

      if (res.data.success) redirect(new NavigationTarget({ url }))
    } catch (error) {
      showError(readError(error, 'Error'))
    } finally {
      setIsSubmitting(false)
    }
  }

  let disabled = isSubmitting || !email.isValid

  return (
    <BaseLayout>
      <Section id="GoogleSignupEmail">
        <Heading>Sign Up</Heading>
        <h5>We use Google to keep your AutoGive account safe & secure!</h5>
        <img src={googleSignupEmailPng} alt="Google Signin" className="centered-svg" />
        <TOSLinks/>        
        <Form onSubmit={onSubmit}>
          <EmailField
            binding={email}
            intent="gmail"
            required
          />         
          <Button type="submit" disabled={disabled} text={isSubmitting ? 'Sending...' : 'Continue'} />
        </Form>
        <br/>
        <p style={{textAlign: 'center'}}><Icon icon="lock" size="x-sm" />&nbsp;We’ll email you a link to verify your Gmail account.</p>
      </Section>
    </BaseLayout>
  )
}

export default GoogleSignupEmail
