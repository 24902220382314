import React, { useEffect, useState } from 'react'
import { postData } from 'helpers/post-data'

import {
  Icon,
  Section,
  Time,
  Subhead,
  Form,
  Heading,
  GroupProgressBar,
  MoneyInput,
  ProgressBar,
  UserText,
  TextArea,
  TextField,
  Link
} from 'components'
import { DEFAULT_CURRENCY } from 'config'
import { filter, pluralize, reduce } from 'helpers'
import { useApiQuery, useFormField } from 'hooks'
import { builder } from 'routes'
import { Money, Money as MoneyHelper } from 'data'

import Button from './Button'

import './Overview.scss'

interface Props {
  groupId: GroupId
  onLoadingChange: (isLoading: boolean) => void  
}
const Overview: React.FC<Props> = ({ groupId, onLoadingChange }) => {
  const group = useApiQuery('/api/group/entity', { groupId })
  const visits = useApiQuery('/api/group/visits', { groupId })
  const permissions = useApiQuery('/api/group/me', { groupId })
  const memberOnlyData = useApiQuery('/api/group/memberOnlyData', { groupId })
  const memberships = useApiQuery('/api/group/members', { groupId })
  const actionItems = useApiQuery('/api/group/actionItems', { groupId })
  const groupStats = useApiQuery('/api/group/stats', { groupId })
  const deposits = useApiQuery('/api/deposit/group', { groupId })
  const [isEditing, setIsEditing] = useState(false)
  const [isGrpEdit, setIsGrpEdit] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)

  const [isAmt, setIsAmt] = useState({})

  const groupDesc = useFormField('')
  const groupName = useFormField('') 

  const groupAmount = useFormField(MoneyHelper.zeroWithCurrency(DEFAULT_CURRENCY))

  useEffect(() => {
    // Signal that loading is complete when memberships data is loaded
    if (memberships) {
      onLoadingChange(false);
    }
  }, [memberships])

  const onSubmit = async (e: React.FormEvent) => {}

  const editSaveDesc = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if(isEditing) await postData('/api/group/update', { groupId, publicMessage: groupDesc })
    if(!!group) group.publicMessage = groupDesc.value
    setIsEditing(!isEditing)
  }  

  const editGroupNameAndGoal = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if(isGrpEdit) await postData('/api/group/update', { groupId, name: groupName, goalAmount: groupAmount.value })
    if(!!group) group.name = groupName.value
    setIsGrpEdit(!isGrpEdit)
  }   

  const cancelClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setIsEditing(false)
    setIsGrpEdit(false)
  }     

  const toggleShow = () => {
    setIsExpanded(!isExpanded)
  }

  /*
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ((isValue.length > 5 && e.key !== 'Delete' && e.key !== 'Backspace' && e.key !== 'Enter') || (e.key === '.')) {
      e.preventDefault()
    }
  }  
   */

  const groupDescSetValue = groupDesc.setValue
  const groupNameSetValue = groupName.setValue
  const groupGoalSetValue = groupAmount.setValue
  useEffect(() => {
    if (group?.publicMessage) {
      groupDescSetValue(group.publicMessage)
      onLoadingChange(false)
    }

    if (group?.name) {
      groupNameSetValue(group.name)
      onLoadingChange(false)
    }

    if (group?.goalAmount) {
      groupGoalSetValue(group.goalAmount)
      onLoadingChange(false)
    } 
 
  }, [group, groupDescSetValue, groupNameSetValue, groupGoalSetValue]) 

  if (!group || !memberships || !permissions || !groupStats) {
    return null
  }

  const isLongNote = group?.publicMessage?.length && group.publicMessage.length > 260 ? true : false
  const descNotEmpty = group?.publicMessage?.length && group.publicMessage.length > 0 ? true : false
  const payerCount = memberships.filter(m => filter.money.nonZeroOrNull(m.amount)).length
  const amountContainsHidden = memberships.map(m => m.amount).filter(filter.isEmpty).length > 0

  return (
    <Section 
      id="Overview"
      headerLeading={<Subhead>{isGrpEdit ? 'Edit Group Name or Goal' : 'Group Details'}</Subhead>}    
      headerTrailing={permissions.permissions.editGroupName && <>
        <button className="ButtonV2 none small" onClick={!isGrpEdit ? () => setIsGrpEdit(true) : cancelClick}>{!isGrpEdit ? 'Edit' : 'Cancel'}</button>
        {isGrpEdit && <button onClick={editGroupNameAndGoal} className="ButtonV2 primary small mg-lf--xs">Save</button>}
      </>}  
    >
    {/*headerTrailing={!isSignedIn && <SignInLink />}*/}    
      {!isGrpEdit && <Heading>{group.name ? group.name : group.recipientFullName}</Heading>}
      {isGrpEdit && <>
        <TextField binding={groupName} placeholder="Group Name"/>          

         {group.goalAmount &&
          <MoneyInput
            binding={groupAmount}
            min={MoneyHelper.amountWithCurrency(5_00, DEFAULT_CURRENCY)}
            max={MoneyHelper.amountWithCurrency(100_000_00, DEFAULT_CURRENCY)}
            disableFractionalAmounts
            required
          />
        }
      </>}
      <hr/>

      {group?.goalAmount && !!groupStats.sum &&
        <ProgressBar
          goal={groupAmount.value}
          donated={groupStats.sum}
          name={group.recipientFullName}
        />
      }

      {/*<div className="OverviewTotal">
        {/* Hiding this feature for now 7/31/24 
          {(actionItems?.kyc.action !== 'provideKyc' && permissions?.permissions.viewDeposits) &&
          <Link classes="ButtonV2 none small" to={builder.group.kyc(groupId)}>Bank Info</Link>
        }
      </div>*/}
      {/*Add this class to group description to add purple bg styles: "publicMessage"*/}

      <div className="d-flex j-between mg-btm--sm group-desc--wrap">
        <strong className="mg-btm--xs">{group.organizerFullName} says...</strong><br/>
        {permissions.permissions.editPublicMessage && <>
          <div>
            <button className="ButtonV2 none small" onClick={!isEditing ? editSaveDesc : cancelClick}>
              {!isEditing
                ? descNotEmpty ? 'Edit' : 'Add'
                : 'Cancel'}
            </button>
            {isEditing && <button onClick={editSaveDesc} className="ButtonV2 primary small mg-lf--xs">Save</button>}
          </div>
        </>}
      </div>
      {(!isEditing && descNotEmpty) && <>            
        <div
          className={`desc-container ${isExpanded ? 'expanded' : 'collapsed'}`}
        >
          <UserText text={group?.publicMessage} />
          <div style={{ lineHeight: 1 }}>&nbsp;</div>
        </div>
        { isLongNote && <button onClick={toggleShow}>
          {isExpanded ? 'Show Less' : 'Show More'}
        </button> }
      </>} 
      {isEditing && (
        <Form onSubmit={onSubmit}>
          <TextArea
            binding={groupDesc}
            placeholder="Please describe why you are collecting money for this group..."
          />
        </Form>
      )}  

      <div className="OverviewRow mg-top--lg">
        <Icon icon="currency" size="small" />from <span className="count">{payerCount}</span> <span className="groupMembers">group {pluralize('member', payerCount)}</span>
      </div>

{/*      <div className="OverviewRow">
        <Icon icon="user" size="small" /><span className="count">{visits?.count}</span> visitors to group join page
      </div>*/}

      <div className="OverviewRow">
        <Icon icon="user" size="small" />organized by <span className="organizerName">{group.organizerFullName}</span>
      </div>

      <div className="OverviewRow">
      <Icon icon="clock" size="small" />created on {!memberOnlyData ? <Time unixtimeSeconds={group.createdAt} /> : <Time unixtimeSeconds={memberOnlyData.createdAt} />}
      </div>

      {!!deposits && deposits.length > 0 && <>
        {(permissions?.permissions.viewDeposits || permissions?.permissions.viewPayments) && <>
          <hr/>
          <div className="d-flex j-between">
            <span>{permissions?.permissions.viewPayments && <Link to={builder.group.paymentHistory(groupId)}>Payment History</Link>}</span>          
            {/*<span>{permissions?.permissions.viewDeposits && <Link to={builder.group.kyc(groupId)}>Bank Info</Link>}</span>*/}
            <span>{permissions?.permissions.viewDeposits && <Link to={builder.group.depositHistory(groupId)}>Deposit History</Link>}</span>
          </div>
          <hr/>
        </>}    
      </>}  
      {/*<DetectAndRedirectUser disabled={isAnchorButtonDisabled} groupId={groupId} btnText="Select Contacts" />*/}
      {/*url={`/group/${groupId}/invite-others`}*/}

      {permissions.permissions.inviteMembers && (
        actionItems?.recipient.action === 'invite' ? (
          <Button
            text={`Invite ${group.recipientFullName} Now!`}
            url={builder.group.inviteRecipient(groupId)}
            size="large"
            intent="success"
          />
        ) : (
          <Button
            text={`${payerCount > 0 ? 'Want more help? Invite others!' : 'No donors yet. Invite them now!'}`}
            url={`/group/${groupId}/invite-email`}            
            size="large"
            intent="primary"
          />
          // url={builder.group.inviteContacts(groupId)}
        )
      )}

    </Section>
  )
}

export default Overview
