import * as config from 'config';
import React, { useEffect } from 'react'
import BaseLayout from 'blocks/layout/base-layout'
import { postData } from 'helpers/post-data'
import {
  Heading,
  Section,
  TOSLinks,
  Icon
} from 'components'
import { NavigationTarget } from 'data'
import { builder } from 'routes'
import { useRedirect, useAppWideState } from 'hooks'
import googleSignupPng from 'assets/images/google-signup.png'
import { useSearchParams } from 'react-router-dom'

import { GoogleLogin, GoogleOAuthProvider} from '@react-oauth/google'

const CLIENT_ID = config.REACT_APP_GOOGLE_CLIENT_ID;

const WelcomeBack: React.FC = () => {
  const redirect = useRedirect()
  // eslint-disable-next-line
  const { isSignedIn, signIn } = useAppWideState()
  const url = builder.group.googleSuccess

  const [searchParams] = useSearchParams() // Get the query params  
  const email = searchParams.get('email')
  const confirmCode = searchParams.get('confirmCode')

  const trackEvent = (n: string) => {
    postData('/api/analytics/track', { funnel: "GROUP_CREATE", event: n, url: window.location.href })
    return false
  }

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        const res = await postData('/api/unregistered/confirm/email', {
          email,
          confirmCode
        })
        console.log(res)
      } catch(error) {
        console.log('confirming email error:', error)
      }      
    }
    if (email && confirmCode) confirmEmail();
    trackEvent('APP_GC_WELCOME_BACK')
  }, [])   

  const onGoogleSignUp = async (r: any) => {
    try {
      const result = await postData('/api/auth/google/signup', {token: r.credential})

      sessionStorage.setItem('googleName', result.data.name.split(' ')[0]) 
      trackEvent('APP_GC_SIGNUP_W_GOOGLE')
      signIn(result.data.token) 
   
      if (result.data.existingUser) {
        // Add in new screen for existing users to better guide next steps
        redirect(new NavigationTarget({ url }))
      } else {
        redirect(new NavigationTarget({ url })) 
      }
    } catch (error) {
      console.log('error', error)
    }
  }  

  return (
    <BaseLayout>
      <Section id="WelcomeBack">
        <Heading>Welcome Back!</Heading>
        <h5>Now, let’s use your Gmail account to create your AutoGive account.</h5>
        <img src={googleSignupPng} alt="Google Signin" className="centered-svg" />
        <TOSLinks/>        
        <Section id="SignUpWithGoogle">
          <GoogleOAuthProvider clientId={CLIENT_ID}>
            <GoogleLogin
              onSuccess={onGoogleSignUp}
              text="signup_with"
              context="signup"
              shape="pill"
            />
          </GoogleOAuthProvider>
        </Section>  
        <br/>
        <p style={{textAlign: 'center'}}><Icon icon="lock" size="x-sm" />&nbsp;Signing up with Google provides you<br/> with secure access to AutoGive.</p>
      </Section>
    </BaseLayout>
  )
}

export default WelcomeBack
