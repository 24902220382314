import * as config from 'config';
import React, { useEffect, useState } from 'react'
import BaseLayout from 'blocks/layout/base-layout'
import { postData } from 'helpers/post-data'
import {
  Button,
  Form,
  Heading,
  Section,
  TOSLinks
} from 'components'
import { NavigationTarget } from 'data'
import { builder, groupIdInUrl, mustBeSignedIn } from 'routes'
import { useRedirect, useAppWideState } from 'hooks'
import googleContactsSvg from 'assets/images/invite-contacts.svg'
import DetectAndRedirectUser from 'helpers/DetectAndRedirectUser'
import { gapi } from 'gapi-script'

const CLIENT_ID = config.REACT_APP_GOOGLE_CLIENT_ID;
const SCOPES = config.REACT_APP_GOOGLE_SCOPES;
const DISCOVERY_DOCS = [config.REACT_APP_GOOGLE_DISCOVERY_DOCS];

interface InviteByEmailProps {
  groupId: GroupId;
}

export const InviteOthers = (props: InviteByEmailProps) => {
  const { groupId } = props;
  const redirect = useRedirect()
  const { isSignedIn, signIn } = useAppWideState()
  const url = builder.group.googleSuccess 

  useEffect(() => {
    function initClient() {
      gapi.client.init({
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      }).then(() => {
        const authInstance = gapi.auth2.getAuthInstance()
        const isSignedIn = authInstance.isSignedIn.get()
      }).catch((error: any) => {
        console.error('Error during Google API client initialization:', error)
      })
    }
    gapi.load('client:auth2', initClient)
    postData('/api/analytics/track', { funnel: "INVITE_MEMBERS", event: "APP_IM_INVITE_OTHERS", url: window.location.href })
  }, [])

  const handleSignIn = () => {
    const authInstance = gapi.auth2.getAuthInstance()
    postData('/api/analytics/track', { funnel: "INVITE_MEMBERS", event: "APP_IM_INVITE_OTHERS_PERMISSIONS", url: window.location.href })
    authInstance.signIn().then(() => {
      redirect(new NavigationTarget({
        url: builder.group.loadContactsGmail(groupId),
      }))    
    }).catch((error: any) => {
      console.error('Google Sign-In Error:', error)
    })
  }  

  return (
    <BaseLayout>
      <Section id="InviteOthers">
        <Heading>Inviting Others</Heading>
        <h5><strong style={{textDecoration: 'underline'}}>Only</strong> the Gmail contacts you select will receive your AutoGive invitation.</h5>
        <img src={googleContactsSvg} alt="Invite Contacts" className="centered-svg" />
        <Button type="button" onClick={handleSignIn} text="View & Select Contacts" extraClass="google-btn" /> 
        <br/>
        <p style={{textAlign: 'center'}}><strong style={{textDecoration: 'underline'}}>Only</strong> the contacts you select will receive your invitation to your AutoGive group.</p>
      </Section>
    </BaseLayout>
  )
}

export default mustBeSignedIn(groupIdInUrl(InviteOthers));
