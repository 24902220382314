import React, { useState, useEffect } from 'react'
// hack
import { useQuery } from 'react-query';
import { postData } from 'helpers/post-data'

import { Section } from 'components'
import { useApiQuery } from 'hooks'
import { builder } from 'routes'

import Button from './Button'

import menuClosed from 'assets/svg-icons/nav-menu-closed.svg'

import './GroupSelector.scss'


interface Props {
  selectedGroupId: GroupId
  onLoadingChange: (isLoading: boolean) => void   
}

const GroupSelector: React.FC<Props> = ({ selectedGroupId, onLoadingChange }) => {
  const group = useApiQuery('/api/group/entity', { groupId: selectedGroupId })
  const actionItems = useApiQuery('/api/group/actionItems', { groupId: selectedGroupId })
  const groupIds = useApiQuery('/api/group/mine', undefined)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    // Signal that loading is complete when group and action items data is loaded
    if (group && actionItems) {
      onLoadingChange(false);
    }
  }, [group, actionItems])  

  if (!group || !actionItems) {
    return null
  }

  const mainSelectorElm = document.getElementById('GroupSelector')
  const selectorWidth = mainSelectorElm ? mainSelectorElm.offsetWidth : 200

  let action: React.ReactElement | null = null
  if (group.memberInviteCount < 1) {
    action = <Button
      intent="success"
      text={'Invite Donors'}
      url={`/group/${selectedGroupId}/invite-email`}
    />
  } else {
    if (actionItems.kyc.action !== 'none') {
      action = <Button
        intent="success"
        url={builder.group.kyc(selectedGroupId)}
        text="Add Bank!"
      />
    } else if (actionItems.recipient.action !== 'none') {
      action = <Button
        disabled={actionItems.recipient.action === 'remind'}
        intent="success"
        text={actionItems.recipient.action === 'invite' ? 'Invite!' : 'Pending'}
        url={`/group/${selectedGroupId}/invite-email`}
      />
    } 
  } 

  return (
    <Section id="GroupSelector">
      <section className={`${isOpen ? 'isOpen' : 'isClosed'}`}>
        <span className="header-wrap"> 
          <span>Group: </span> 
          <span className="name">{group.recipientFullName}</span>
          {/*this shows group name vs recipient name*/}
          {/*<span className="name">{group.name ? group.name : group.recipientFullName}</span>*/}
          {action}
          {groupIds && groupIds.length > 1 ? (
            <button
              type="button"
              className="dropdown-trigger"
              onClick={() => setIsOpen(!isOpen)}
            >
              <img src={menuClosed} alt="Menu Icon" />
            </button> ) : null
          }
        </span>

        {groupIds && (
          <div className="dropdown-wrap" style={{width: selectorWidth + 'px'}}>       
            {groupIds.map(id => <GroupItem groupId={id} key={id} selectedId={selectedGroupId}/>)}
          </div>
        )}
      </section>  
    </Section>
  )
  /*
     For now, I don't want to deal with avoiding weird bouncing as data
     loads in. There's no design for it yet anyway.

     I'm trying to prototype something with Suspense, but haven't used it
     before and the docs are far from amazing. Another path (which we'll
     likely want for unrelated reasons) is some sort of useEntities(ids)
     hook that helps sidestep the "hook in a loop" problem, but that's
     likely going to require API changes (at least to be remotely
     performant, though that's the case either way). I don't thing it's
     prudent to address that yet.

     <React.Suspense fallback={<p>LOADINGGGGG</p>}>
     <p onClick={() => setIsOpen(o => !o)}><Icon icon="checked" />openclose</p>
     {isOpen && <GroupList /> }
     </React.Suspense>
   */
}

const GroupItem: React.FC<{ groupId: GroupId; selectedId: string }> = ({ groupId, selectedId }) => {
  const suspended = useDeferredGroup(groupId)
  const name = suspended.name ? suspended.name : suspended.recipientFullName
  const isCurrentMember = selectedId === groupId ? true : false

  return (
    isCurrentMember ? (
      <span className="dropdown-item">{name}</span>
    ) : (
      <a href={`/group/${suspended.id}/view`} className="dropdown-item">{name}</a>
    )
  )
};

/*
 * This is a straight copy of useApiQuery with the suspense flag set. It's
 * enough to prototype here, but insufficient for a real app.
 */
const useDeferredGroup = (groupId: GroupId): Api.Entity.Group => {
  const result = useQuery(
    ['/api/group/entity', groupId],
    () => postData('/api/group/entity', { groupId }),
    {
      suspense: true
    }
  )
  // @ts-ignore
  return result.data.data
}

export default GroupSelector
