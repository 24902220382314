import React, { useEffect, useState } from 'react'

import BaseLayout from 'blocks/layout/base-layout'
import { postData } from 'helpers/post-data'

import {
  Button,
  Form,
  Heading,
  P,
  TextArea,
  TextField
} from 'components'
import { NavigationTarget } from 'data'
import { readError } from 'helpers'
import { useFormField, useRedirect, useAppWideState } from 'hooks'
import { builder, mustBeSignedIn } from 'routes'

interface Props {
  groupId?: GroupId
}
const SetPublicMessage: React.FC<Props> = () => {
  const { isSignedIn, showError, user } = useAppWideState()
  const name = useFormField('')
  const message = useFormField('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const redirect = useRedirect()
  // const disabled = !message.isValid || !name.isValid
  const groupFor = sessionStorage.getItem('groupFor')
  const recipientName = sessionStorage.getItem('recipientName')
  const url = builder.group.googleViewGroup

  useEffect(() => {
    postData('/api/analytics/track', { funnel: "GROUP_CREATE", event: "APP_GC_GROUP_NAME", url: window.location.href })
  }, [])

  useEffect(() => {
    const storedName = sessionStorage.getItem('groupName')
    const storedDesc = sessionStorage.getItem('groupDesc')    

    if (storedName) {
      name.setValue(storedName)
    }
    if (storedDesc) {
      message.setValue(storedDesc)
    }
  }, [message, name])

  const onType = (e: React.KeyboardEvent) => {
    setIsSubmitting(false)
  }

  const nameMessage = !name.isValid && isSubmitting ? 'Group Name is required' : null
  const descMessage = !message.isValid && isSubmitting ? 'Group Description is required' : null
  const isLoading = nameMessage === null && descMessage === null && isSubmitting ? true : false

  const goalAmountAmount = sessionStorage.getItem('goalAmount.amount')
  const goalAmountCurrency = sessionStorage.getItem('goalAmount.currency')
  if (!goalAmountAmount || !goalAmountCurrency) {
    redirect(new NavigationTarget({ url: builder.group.setGroupGoal }))
  }
  const goalAmount: Money = {
    amount: goalAmountAmount as string,
    currency: goalAmountCurrency as Currency,
  }  

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsSubmitting(true)

    if (name.isValid && message.isValid) {
      try {
        if (groupFor === 'self') {
          await postData('/api/group/create/self', { name, goalAmount, publicMessage: message })
        } else {
          await postData('/api/group/create/other', { recipientName, name, goalAmount, publicMessage: message })
        }
        redirect(new NavigationTarget({ url }))
        return true
      } catch (error) {
        showError(readError(error, 'Could not create group'))
        return false
      }
    }
  }

  return (<BaseLayout>
    <div id="SetWelcomeNote">
      <Form onSubmit={onSubmit}>
        <Heading>Describe Your Need</Heading>
        <h5>Invited donors will see the information you enter below.</h5>

        <TextField
          binding={name}
          placeholder="Title (e.g. Help with College)"
          required={true}
          errorMessage={nameMessage}
        />
        {/*onKeyPress={onType}*/}
        <TextArea
          binding={message}
          placeholder="Write a short message to describe your group and why you're collecting donations..."
          required={true}
          errorMessage={descMessage}
          onKeyUp={onType}
        />

        <Button type="submit" text="Next" loading={isLoading} />
        <P centered={true} classes="pd-lfrt">Don’t worry, you can change this information later.</P>
      </Form>
    </div>
  </BaseLayout>)
}

export default mustBeSignedIn(SetPublicMessage)
