import React from 'react';
import classNames from 'classnames'
import googleLogo from 'assets/images/google-logo.svg'

import './index.css';

interface Props {
  href?: string
  children: Children
  id?: string
  intent?: Intent
  color?: string
  extraClass?: string
  disabled?: boolean
  onClick: React.MouseEventHandler<HTMLAnchorElement>
}

/**
 * A link that behaves like a button.
*/
const AnchorButton: React.FC<Props> = ({ intent = 'none', children, href, id, disabled, onClick, color, extraClass = '' }) => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (disabled) {
      event.preventDefault();
      return;
    }
    onClick?.(event);
  };
  return (
    <a
      id={id}
      href={disabled ? undefined : href}
      role="button"
      aria-disabled={disabled}
      className={classNames("AnchorButton", extraClass, {
        'intent-none': intent === 'none',
        'intent-secondary': intent === 'secondary',
        'intent-warning': intent === 'warning'
      })}
      onClick={handleClick}
      style={{
        pointerEvents: disabled ? 'none' : 'auto',
        opacity: disabled ? 0.5 : 1,
        cursor: disabled ? 'not-allowed' : 'pointer',
        color: color
      }}
    >
      {children}
      {extraClass?.includes('google-btn') &&
          <img src={googleLogo} alt="Google Signin" />
      }
    </a>
  );
};

export default AnchorButton;
