import React, { useEffect, useState } from 'react';

import { NavigationTarget } from 'data'
import { Heading, Link, Icon, P } from 'components'
import { NODE_ENV } from 'config'
import { postData } from 'helpers/post-data'
import { useFeatureToggle, useAppWideState, useRedirect } from 'hooks'
import { builder } from 'routes'

import './index.scss'
import iconHomepageFooter from 'assets/svg-icons/icon-homepage-footer.svg'
import plansSvg from 'assets/images/ag-plans.svg'


/**
 * Internal dependencies
 */
import BaseLayout from 'blocks/layout/base-layout'

const checklistItems = [
  { id: 1, title: 'Supported Country', description: 'I’m in the United States.' },
  { id: 2, title: 'Gmail Access', description: 'I have a Google Account.' },
  { id: 3, title: 'Invite Requirement', description: 'I can invite at least 3 people.' }
]

const trackEvent = (n: string) => {
    postData('/api/analytics/track', { funnel: "GROUP_CREATE", event: n, url: window.location.href })
    return false
}

const Home = () => {
  const showButtonHere = useFeatureToggle('showCreateGroupLinkOnHomepage')
  const showButtonAnywhere = useFeatureToggle('showCreateGroupLinks')
  const [checkedItems, setCheckedItems] = useState<{ [key: number]: boolean }>({})
  const { isSignedIn } = useAppWideState()
  const redirect = useRedirect()

  if (isSignedIn) redirect(new NavigationTarget({ url: '/my-groups' }))

  const toggleCheck = (id: number) => {
    setCheckedItems(prev => ({
      ...prev,
      [id]: !prev[id] // Toggle the state of the clicked item
    }))
    switch (id) {
        case 1: 
            trackEvent('APP_GC_GETTING_STARTED_IN_US')
            return
        case 2: 
            trackEvent('APP_GC_GETTING_STARTED_HAS_GMAIL')
            return
        case 3: 
            trackEvent('APP_GC_GETTING_STARTED_HAS_3')
            return
    }
  }

  useEffect(() => { trackEvent('APP_GC_GETTING_STARTED') }, [])

  let allChecked = (Object.keys(checkedItems).length === Object.keys(checklistItems).length && Object.values(checkedItems).every(el => el === true)) ? true : false;

  // switch (NODE_ENV) {
  //   case 'production':
  //       window.location.href = 'https://www.autogive.com/'
  //       return null
  //   default:
      return (
        <BaseLayout showNavigation>
          <div id="Home">
            <Heading>Getting Started</Heading>
            <h5>Check the boxes to confirm you’re ready to collect monthly donations.</h5>

            <article style={{ paddingBottom: '1rem' }}>
              <img src={plansSvg} alt="AutoGive Plans" className="centered-svg" />

              {checklistItems.map(({ id, title, description }) => (
                <div key={id} className="checklist-wrap" onClick={() => toggleCheck(id)}>
                  <Icon icon={checkedItems[id] ? 'checked' : 'unchecked'} size="medium" inline />
                  <div className="checklist-wrap--text">
                    <P>{title}</P>
                    <span>{description}</span>
                  </div>
                </div>
              ))}
            </article>  

            <Link to={builder.group.googleSignUpEmail} button classes={allChecked ? '' : 'inactive'}>Let's go!</Link>
          </div>
        </BaseLayout>
      )
  }
// }

export default Home
