import { useAppWideState, useRedirect } from 'hooks';
import { AnchorButton } from 'components';
import { NavigationTarget } from 'data'
import { postData } from 'helpers/post-data'
import { builder } from 'routes'

const trackEvent = (n: string, gid: string, isGmail: boolean) => {
  postData('/api/analytics/track', { funnel: "GROUP_CREATE", event: n, url: window.location.href, groupId: gid, props: {isGmail: isGmail}})
  return false
}

interface DetectAndRedirectProps {
  groupId: GroupId
  btnText?: string
  disabled?: boolean
  classes?: string
}

export const DetectAndRedirectUser = (props: DetectAndRedirectProps) => {
  const { groupId, btnText, disabled, classes } = props;
  const { user } = useAppWideState();
  const redirect = useRedirect()
  const handleEmailRedirect = () => {
    if (!user || !user.email) {
      console.error("User email is not available");
      return;
    }

    const email = user.email;
    const emailDomain = email.substring(email.lastIndexOf("@") + 1).toLowerCase();

    trackEvent('APP_GC_INVITE_DETECT_GMAIL', groupId, true)
    redirect(new NavigationTarget({
      // url: builder.group.loadContactsGmail(groupId),
      url: builder.group.inviteOthers(groupId)
    }))    

    // if (emailDomain === "gmail.com" || emailDomain === "googlemail.com") {
    //   trackEvent('APP_GC_INVITE_DETECT_GMAIL', groupId, true)
    //   redirect(new NavigationTarget({
    //     url: builder.group.loadContactsGmail(groupId),
    //   }))
    // } else {
    //   trackEvent('APP_GC_INVITE_DETECT_GMAIL', groupId, false)
    //   redirect(new NavigationTarget({
    //     url: builder.group.addContactsNonGmail(groupId),
    //   }))
    // }
  };

  return (
    <AnchorButton extraClass={`Button ${classes}`} disabled={disabled} onClick={handleEmailRedirect} > {btnText} </AnchorButton>
  );
};

export default DetectAndRedirectUser;
