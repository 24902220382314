import React, { useEffect, useState } from 'react'
import BaseLayout from 'blocks/layout/base-layout'
import {
  Link,
  Form,
  Heading,
  Section,
  Icon, 
  CircleNumber
} from 'components'
import { postData } from 'helpers/post-data'
import { builder, mustBeSignedIn } from 'routes'
import googleSuccessSvg from 'assets/images/google-success.svg'
import { showConfetti } from 'helpers'

const GoogleSuccess: React.FC = () => {
  showConfetti()
  const trackEvent = (n: string) => {
    postData('/api/analytics/track', { funnel: "GROUP_CREATE", event: n, url: window.location.href })
    return false
  }
  useEffect(() => { trackEvent('APP_GC_SIGNUP_W_GOOGLE_SUCCESS') }, [])

  const firstName = sessionStorage.getItem('googleName')

  return (
    <BaseLayout>
      <Section id="GoogleSuccess">
        <Heading>Hi {firstName}!</Heading>
        <h5>Get started in 3 simple steps. Here’s what to expect next :)</h5>
        <img src={googleSuccessSvg} alt="Google Signin" className="centered-svg" />

        <article style={{ paddingBottom: '1rem' }}>
          <div className="checklist-wrap">
            <CircleNumber text="1" />
            <div className="checklist-wrap--text">
              <h4>Set your goal.</h4>
            </div>
          </div>

          <div className="checklist-wrap">
            <CircleNumber text="2" />
            <div className="checklist-wrap--text">
              <h4>Describe your need.</h4>
            </div>
          </div>

          <div className="checklist-wrap">
            <CircleNumber text="3" />
            <div className="checklist-wrap--text">
              <h4>Invite others to give.</h4>
            </div>
          </div>
        </article> 

        <Link to={builder.group.createForSelf} button>Next</Link>
      </Section>
    </BaseLayout>
  )
}

export default mustBeSignedIn(GoogleSuccess)