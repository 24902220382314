import { useEffect, useState } from 'react'

import {
  Button,
  EmailField,
  Form,
  Heading,
  LoaderHeart,
  Icon,
  Link,
  P,
  PasswordField,
  PhoneNumberField,
  Section,
  TextField,
  TOSLinks,
} from 'components'
import { constantStrings, NavigationTarget } from 'data'
import { readError, showConfetti } from 'helpers'
import { useAppWideState, useFormField, useRedirect } from 'hooks'
import { builder, mustBeSignedIn } from 'routes'

import BaseLayout from 'blocks/layout/base-layout'
import { postData } from 'helpers/post-data'
import '../index.scss'

const CreateGroupForSelf: React.FC = () => {
  const { isSignedIn, signIn, showError } = useAppWideState()

  const email = useFormField('')
  const password = useFormField('')
  const phone = useFormField('')
  const name = useFormField('')
  const recipientName = useFormField('')

  const redirect = useRedirect()

  const [groupFor, setGroupFor] = useState<string | null>('self')
  let enableButton = groupFor === 'other' && recipientName.value.length < 3 ? true : false;

  useEffect(() => {
    postData('/api/analytics/track', { funnel: "GROUP_CREATE", event: "APP_GC_GROUP_CREATE_DOORS", url: window.location.href })
  }, [])

  const toggleActive = (id: string) => setGroupFor(groupFor === id ? id : id)

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // setIsLoading(true)

    // const url = builder.group.setPublicMessage(groupId)
    if(!!groupFor) sessionStorage.setItem('groupFor', groupFor)
    if(!!recipientName.value) sessionStorage.setItem('recipientName', recipientName.value)
    sessionStorage.removeItem('groupName')
    sessionStorage.removeItem('groupDesc')

    const url = builder.group.setGroupGoal
    redirect(new NavigationTarget({ url }))
  }  

  return (
    <BaseLayout>
      <Section id="CreateGroupForSelf">
        <Form onSubmit={onSubmit}>
          <Heading>Collect money for...</Heading>
          <div id="TabBar">
            <section className={groupFor === 'self' ? 'active' : ''} onClick={() => toggleActive('self')}>
              <h4>Myself</h4>
              <p>Monthly donations will be deposited into your bank account.</p>
            </section>   
            <section className={groupFor === 'other' ? 'active' : ''} onClick={() => toggleActive('other')}>
              <h4>Someone Else</h4>
              <p>You’ll invite the recipient to add their bank & receive deposits.</p>
            </section>                      
          </div>
          {groupFor === 'other' &&
            <div>
              <P classes="mg-top--2xl font-semibold sib--top-mg-sm">Who will receive the money?</P>
              <TextField binding={recipientName} placeholder="Recipient's Full Name" required />
              <P classes="font-semibold">You’ll invite the Recipient to add their bank account after you.</P>
              <P>A US Social Security Number or Tax Identification Number is required to receive monthly deposits.</P>
            </div>
          }
          <Button type="submit" text="Next" disabled={enableButton}/>
        </Form>
      </Section>
    </BaseLayout>
  )
}

export default mustBeSignedIn(CreateGroupForSelf)
