import { Heading, P, AnchorButton } from 'components';
import { postData } from 'helpers/post-data'
import { groupIdInUrl, mustBeSignedIn, builder } from 'routes';
import { useApiQuery, useRedirect } from 'hooks';
import { NavigationTarget } from 'data'
import { showConfetti } from 'helpers'
import DetectAndRedirectUser from 'helpers/DetectAndRedirectUser';
import BaseLayout from 'blocks/layout/base-layout';
import { useEffect } from 'react';
import sentSvg from 'assets/images/well-done.svg'

interface SuccessProps {
  groupId: GroupId
  inviteCount?: number
}

declare var window: any; // this will cause TS not to complain about window.fpq

export const InviteByEmailSuccess = (props: SuccessProps) => {
  const { groupId } = props as { groupId: GroupId }
  const { inviteCount } = props as { inviteCount: number }
  const redirect = useRedirect()
  const group = useApiQuery('/api/group/entity', { groupId })
  const memberInviteCount = group?.memberInviteCount || 0

  useEffect(() => {
    postData('/api/analytics/track', { funnel: "INVITE_MEMBERS", event: "APP_IM_INVITES_SENT", url: window.location.href, props: {memberInviteCount: inviteCount}})
  })

  const handleDoneInviting = () => {
    redirect(new NavigationTarget({
      url: builder.group.view(groupId),
    }))
  };

  return (
    <BaseLayout>
      <div className="success-page font-16px">
        <div className="success-card">
          <Heading>Invites Sent!</Heading>
          <h5>Nicely done. You sent <strong>{memberInviteCount}</strong> invites!</h5>
          <img src={sentSvg} alt="Google Signin" className="centered-svg" />
          <DetectAndRedirectUser groupId={groupId} btnText="Send More Invites?" />

          <AnchorButton extraClass="d-flex j-center mg-top--sm font-w-reg" onClick={handleDoneInviting}>
            I’m done inviting others.
          </AnchorButton>
        </div>
      </div>
    </BaseLayout>
  );
};

export default mustBeSignedIn(groupIdInUrl(InviteByEmailSuccess));
