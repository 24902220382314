import { useEffect } from 'react'
import {
  Button,
  CustomSlider,
  Form,
  Heading,
  MoneyBox,
  MoneyInput,
  P,
  Section,
} from 'components'
import { DEFAULT_CURRENCY } from 'config'
import { Money as MoneyHelper, NavigationTarget } from 'data'
import { useFormField, useRedirect } from 'hooks'
import { builder, mustBeSignedIn } from 'routes'

import BaseLayout from 'blocks/layout/base-layout'
import { postData } from 'helpers/post-data'
import '../index.scss'

const CreateGroupSetGoal: React.FC = () => {
  const redirect = useRedirect()
  const amount = useFormField(MoneyHelper.amountWithCurrency(125000, DEFAULT_CURRENCY)) // Initial value

  useEffect(() => {
    postData('/api/analytics/track', { funnel: "GROUP_CREATE", event: "APP_GC_GROUP_SET_GOAL", url: window.location.href })
  }, [])

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    sessionStorage.setItem('goalAmount.amount', amount.value.amount.toString())
    sessionStorage.setItem('goalAmount.currency', amount.value.currency)
    redirect(new NavigationTarget({ url: builder.group.setGroupDetails }))
  }

  const min = MoneyHelper.amountWithCurrency(250_00, DEFAULT_CURRENCY)
  const max = MoneyHelper.amountWithCurrency(10_000_00, DEFAULT_CURRENCY)

  return (
    <BaseLayout>
      <Section id="CreatGroupSetGoal">
        <Form onSubmit={onSubmit}>          
          <Heading>Set Monthly Goal</Heading>
          <MoneyBox amount={amount.value} />
          <br/>
          <CustomSlider min={min} max={max} binding={amount} />
          <br/>
          <P classes="font-w-reg mg-top--2xl">Or, enter your monthly goal here:</P>
          <MoneyInput
            binding={amount}
            disableFractionalAmounts
            min={min}
            max={max}
            required
          />
          <br/>
          <Button type="submit" text="Next" disabled={!amount.isValid} />
        </Form>
      </Section>
    </BaseLayout>
  )
}

export default mustBeSignedIn(CreateGroupSetGoal)
