import * as config from 'config';
import React, { useEffect, useState } from 'react'
import BaseLayout from 'blocks/layout/base-layout'
import { postData } from 'helpers/post-data'
import {
  Button,
  Form,
  Heading,
  Section
} from 'components'
import { NavigationTarget } from 'data'
import { builder } from 'routes'
import { useRedirect, useAppWideState } from 'hooks'
import CheckEmailPng from 'assets/images/check-email.svg'

const CheckEmail: React.FC = () => {
  const redirect = useRedirect()
  const { isSignedIn, signIn } = useAppWideState()
  const url = builder.group.googleSuccess

  const trackEvent = (n: string) => {
    postData('/api/analytics/track', { funnel: "GROUP_CREATE", event: n, url: window.location.href })
    return false
  }

  const onGoogleSignUp = async (r: any) => {
    try {
      const result = await postData('/api/auth/google/signup', {token: r.credential})
      console.log('result', result)

      sessionStorage.setItem('googleName', result.data.name.split(' ')[0]) 
      trackEvent('APP_GC_SIGNUP_W_GOOGLE')
      signIn(result.data.token) 
   
      if (result.data.existingUser) {
        // Add in new screen for existing users to better guide next steps
        redirect(new NavigationTarget({ url }))
      } else {
        redirect(new NavigationTarget({ url })) 
      }
    } catch (error) {
      console.log('error', error)
    }
  }  

  return (
    <BaseLayout>
      <Section id="CheckEmail">
        <Heading>Check Your Email</Heading>
        <h5>Please visit the link we just sent to verify your Gmail address.</h5>
        <img src={CheckEmailPng} alt="Google Signin" className="centered-svg" />
        <br/>
        <br/>
        <p style={{textAlign: 'center'}}><strong>No email?</strong> Check your promotions tab and your spam folder. Thanks!</p>
      </Section>
    </BaseLayout>
  )
}

export default CheckEmail
