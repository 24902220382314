import React from 'react'
import classNames from 'classnames'
import './index.css'

type Props = {
  text: string
  classes?: string
}
const CircleNumber: React.FC<Props> = ({ text, classes }) => {
  return <span className={classNames('circle-number', classes)}>{text}</span>
}

export default CircleNumber

