import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

/**
 * This creates a HoC that reads the GroupId out of the URL (via react-router)
 * and provides it to the component.
 */

interface TakesGroupId {
  groupId: GroupId
  inviteCount?: number
}

const groupIdInUrl = (Component: React.FC<TakesGroupId>) => {
  return () => {
    const { groupId } = useParams() // Get the groupId from the URL path
    const [searchParams] = useSearchParams() // Get the query params
    const inviteCount = Number(searchParams.get('inviteCount')) || 3; // Parse inviteCount

    if (!groupId) {
      throw new Error('Invalid Page')
    }

    return <Component groupId={groupId as GroupId} inviteCount={inviteCount} />
  }
}

export default groupIdInUrl
