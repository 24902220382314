import * as config from 'config';
import React, { useEffect, useState, useMemo } from 'react';
import { Button, Heading, P, Form, Icon, TextArea } from 'components';
import { groupIdInUrl, mustBeSignedIn, builder } from 'routes';
import { useFormField, useRedirect, useApiQuery } from 'hooks';
import { NavigationTarget } from 'data'
import invite from 'assets/images/email-invite.svg';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { postData } from 'helpers/post-data'
import { setContacts, toggleContactSelection, setInputText, setErrorMessage } from '../../redux/contactsslice';
import Fuse from 'fuse.js';
import { gapi } from 'gapi-script';
import BaseLayout from 'blocks/layout/base-layout';
import './index.scss';
import { showConfetti } from 'helpers'
import greyContactSvg from 'assets/images/greyed-contacts.svg'

const CLIENT_ID = config.REACT_APP_GOOGLE_CLIENT_ID;
const SCOPES = config.REACT_APP_GOOGLE_SCOPES;
const DISCOVERY_DOCS = [config.REACT_APP_GOOGLE_DISCOVERY_DOCS];

interface Contact {
  id: string;
  name: string;
  email: string;
  selected: boolean;
}

interface LoadContactsGmailProps {
  groupId: GroupId;
}

export const LoadContactsGmail = (props: LoadContactsGmailProps) => {
  const { groupId } = props as { groupId: GroupId }
  const inputValue = useFormField<string>('')
  const [isSignedIn, setIsSignedIn] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const group = useApiQuery('/api/group/entity', { groupId })  
  const mainList = document.querySelector('.contacts-list.one')
  const formHeight = document.querySelector('.load-contacts-gmail form') as HTMLFormElement  

  const letterDivz: JSX.Element[] = []

  const dispatch = useAppDispatch();
  const { contacts, selectedContacts, inputText, errorMessage } = useAppSelector(state => state.contacts);
  const inviteMessage = useAppSelector(state => state.invite.message);

  const selectedDivs = contacts.filter(el => el.selected)

  const redirect = useRedirect()

  const fuse = useMemo(() => new Fuse(contacts, {
    keys: ['name', 'email'],
    threshold: 0.3,
  }), [contacts]);

  useEffect(showConfetti, [])

  useEffect(() => {
    const selectedContactsList = selectedContacts.map(contact => contact.name).join(', ');
    if (selectedContactsList !== inputText) {
      dispatch(setInputText(selectedContactsList));
    }
    if (!selectedContactsList.length) setSearchTerm('');
  }, [selectedContacts, dispatch]);

  useEffect(() => {
    const listContacts = document.querySelector('.load-contacts-gmail .contacts-list.one') as HTMLDivElement;
    const selectedList = document.querySelector('.load-contacts-gmail .contacts-list.two') as HTMLDivElement;
    if (formHeight) {
      listContacts.style.height = `calc(${formHeight.clientHeight}px - 204px)`
      selectedList.style.height = `calc(${formHeight.clientHeight}px - 246px)`
    }   
  }, [inputValue]);  

  const handleContactSelect = (id: string, e: any) => {
    const selectedElm = (e.target as HTMLElement).closest('.selected-item');
    if (!!selectedElm) {
      selectedElm.classList.add('fadeHide') 
      setTimeout(() => dispatch(toggleContactSelection(id)), 215) 
    } else {
      dispatch(toggleContactSelection(id))
    }  
    inputValue.setValue('')
    mainList?.classList.add('hide')
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    dispatch(setInputText(value));
    const lastTerm = value.split(',').pop()?.trim();
    setSearchTerm(lastTerm || '');  
    // deleteContactFromTextarea(value)  
    value === '' ? mainList?.classList.add('hide') : mainList?.classList.remove('hide');
  };

  const addListComma = () => {
    if (inputText.length < 1 || inputText.endsWith(', ')) return;
    dispatch(setInputText(inputText + ', '));
  };

  const handleInviteClick = async () => {
    if (selectedContacts.length < 3) {
      dispatch(setErrorMessage('Please select at least 3 contacts.'));
      return;
    }
    dispatch(setErrorMessage(null));

    try {

      const response = await postData('/api/group/inviteMembers', {
        groupId: groupId,
        message: inviteMessage,
        contacts: selectedContacts.map(contact => ({
          fullName: contact.name,
          email: contact.email,
        })),
      })

      redirect(new NavigationTarget({
        // url: builder.group.inviteByEmailSuccess(groupId),
        url: `/group/${groupId}/invite-email-success?inviteCount=${selectedContacts.length}`
      }))
    } catch (error) {
      console.error('Error sending invites:', error);
      dispatch(setErrorMessage('Failed to send invites. Please try again.'));
    }
  };


  const handleSignIn = () => {
    const authInstance = gapi.auth2.getAuthInstance();
    authInstance.signIn().then(() => {
      setIsSignedIn(true);
      loadContacts2();
    }).catch((error: any) => {
      console.error('Google Sign-In Error:', error);
    });
  };


  useEffect(() => {
    function initClient() {
      gapi.client.init({
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      }).then(() => {
        const authInstance = gapi.auth2.getAuthInstance();
        const isSignedIn = authInstance.isSignedIn.get();
        setIsSignedIn(isSignedIn);

        // Listen for sign-in state changes
        authInstance.isSignedIn.listen(setIsSignedIn);
        if (isSignedIn) {
          loadContacts2();
        }
      }).catch((error: any) => {
        console.error('Error during Google API client initialization:', error);
      });
    }

    gapi.load('client:auth2', initClient);
  }, []);

  const loadContacts = async () => {
    try {
      const [mainContacts, otherContacts] = await Promise.all([fetchMainContacts(), fetchOtherContacts()]);
      const allContacts = [...mainContacts, ...otherContacts].sort((a, b) => a.name.localeCompare(b.name));
      postData('/api/analytics/track', { funnel: "INVITE_MEMBERS", event: "APP_IM_ADD_CONTACTS", url: window.location.href })
      dispatch(setContacts(allContacts));
    } catch (error: any) {
      if (error?.result?.error?.message === "Request had insufficient authentication scopes.") {
        setIsSignedIn(false);
      } else {
        dispatch(setErrorMessage('Error fetching contacts'));
      }
    }
  };

  const loadContacts2 = async () => { // only otherContacts
    try {
      const otherContacts = await fetchOtherContacts();
      const sortedContacts = otherContacts.sort((a, b) => a.name.localeCompare(b.name)); // not sure if this is needed
      postData('/api/analytics/track', { funnel: "INVITE_MEMBERS", event: "APP_IM_ADD_CONTACTS", url: window.location.href })
      dispatch(setContacts(sortedContacts));
    } catch (error: any) {
      if (error?.result?.error?.message === "Request had insufficient authentication scopes.") {
        setIsSignedIn(false);
      } else {
        dispatch(setErrorMessage('Error fetching contacts'));
      }
    }
  };


  const fetchMainContacts = async (): Promise<Contact[]> => {
    try {
      var results:any[] = [];
      var response = await gapi.client.people.people.connections.list({
        resourceName: 'people/me',
        pageSize: 1000,
        personFields: 'names,emailAddresses',
      })
      results = results.concat(response.result.connections);
      var npt = response.result?.nextPageToken
      var max = 3      
      while (npt && --max>0) {
        response = await gapi.client.people.people.connections.list({
          resourceName: 'people/me',
          pageSize: 1000,
          personFields: 'names,emailAddresses',
          pageToken: npt,
        })
        results = results.concat(response.result.connections);
        npt = response.result?.nextPageToken
      }
      return parseContacts(results);
    } catch (error) {
      console.error('Error fetching main contacts:', error);
      throw error;
    }
  };

  const fetchOtherContacts = async (): Promise<Contact[]> => {
    try {
      var results:any[] = [];
      var response = await gapi.client.people.otherContacts.list({
        pageSize: 1000,
        readMask: 'names,emailAddresses',
      })
      results = results.concat(response.result.otherContacts);
      var npt = response.result?.nextPageToken
      var max = 3      
      while (npt && --max>0) {
        response = await gapi.client.people.otherContacts.list({
          pageSize: 1000,
          readMask: 'names,emailAddresses',
          pageToken: npt,
        })
        results = results.concat(response.result.otherContacts);
        npt = response.result?.nextPageToken
      }
      return parseContacts(results);
    } catch (error) {
      console.error('Error fetching other contacts:', error);
      throw error;
    }
  };

  function getEmailPrefix(contact: string) {
    const emailPattern = /<([^\s@]+@[^\s@]+\.[^\s@]+)>|^([^\s@]+@[^\s@]+\.[^\s@]+)$/;
    const match = contact.match(emailPattern);
    if (emailPattern.test(contact)) {
      const emailAddress = match ? (match[1] || match[2]) : '';
      return emailAddress.split('@')[0];
    } else {
      return contact;
    }
  }

  const parseContacts = (contacts: any[]): Contact[] => {
    return contacts.map((person: any, index: number) => {
      const email = person.emailAddresses && person.emailAddresses[0]?.value;
      const name = (person.names && person.names[0]?.displayName) || 'Unknown';
      const displayName = getEmailPrefix(name);
      const letter = displayName.charAt(0).toUpperCase();

      return {
        id: `${index}-${email}`,
        name: displayName,
        email: email || '',
        selected: false,
        letter,
      };
    }).filter((contact: Contact) => contact.email);
  };

  const filteredContactsMemo = useMemo(() => {
    if (searchTerm) {
      return fuse.search(searchTerm).map(result => result.item);
    }
    return contacts
  }, [searchTerm, contacts, fuse])

  const deleteContactFromTextarea = (text: string) => {
    const contact = selectedContacts.filter(el => !text.includes(el.name))
    if (contact.length) {
      dispatch(toggleContactSelection(contact[0].id));
      setSearchTerm('');
    }
    return
  }

  const contactListWithLetterHeaders = (): JSX.Element | null => {
    let previousLetter = ''
    const letterDivs: JSX.Element[] = []

    filteredContactsMemo.forEach((contact, i) => {
      const currentLetter = contact.name.charAt(0)
      const index = i % 2 === 0 ? 'even' : 'odd'
      // Check if the current letter is different from the previous to add a header
      // if (currentLetter !== previousLetter) {
      //   letterDivs.push(
      //     <div key={i} className="contact-header">
      //       {currentLetter}
      //     </div>
      //   )
      //   previousLetter = currentLetter
      // }
      // Always add the contact name under the (possibly new) header
      letterDivs.push(
        <div key={contact.id} className={`contact-item ${index}`}  onClick={(e) => handleContactSelect(contact.id, e)}>
          <Icon icon={contact.selected ? 'checkedthin' : 'uncheckedthin'} size="medium" inline />
          <div>
            <div className="contact-name">{contact.name}</div>
            <div className="contact-email">{contact.email}</div>
          </div>
        </div>
      )
    })

    // Return a fragment containing all headers and names
    return letterDivs.length > 0 ? <>{letterDivs}</> : null
  }

  const selectedContactsList = (): JSX.Element | null => {
    let previousLetter = ''
    const letterDivz: JSX.Element[] = []

    selectedDivs.forEach((contact, i) => {
      const currentLetter = contact.name.charAt(0)
      letterDivz.push(
        <div key={contact.id} className={`contact-item selected-item`}  onClick={(e) => handleContactSelect(contact.id, e)}>
          <Icon icon={contact.selected ? 'checkedthin' : 'uncheckedthin'} size="medium" inline />
          <div>
            <div className="contact-name">{contact.name}</div>
            <div className="contact-email">{contact.email}</div>
          </div>
        </div>
      )
    })

    return letterDivz.length > 0 ? <>{letterDivz}</> : null
  }  

  if (!group) {
    return null
  }

  const invitesSent = group.memberInviteCount >= 3 ? true : false;
  const threshold = invitesSent ? 1 : 3;

  return (
    <BaseLayout hasSections>
      <style>
        {`
          body {
            overflow: hidden;
          }
        `}
      </style>

      {!isSignedIn ? (
        // <div className="add-contacts-gmail Section">
        //   <Heading>Add Contacts</Heading>
        //   <P>Access your Gmail contacts to select who will receive your invitation.</P>

        //   <img
        //     alt="Invite By Gmail"
        //     src={invite}
        //     style={{ userSelect: 'none' }}
        //     width="100%"
        //     className="mg-top--md mg-btm--md"
        //   />
        //   <div className="signin-section">
        //     <Button type="button" onClick={handleSignIn} text="Sign In with Google" />
        //     <P>Only the people you select will be invited to give.</P>
        //   </div>
        // </div>
        <section id="InviteOthers">
{/*          <Heading>Inviting Others</Heading>
          <h5><strong style={{textDecoration: 'underline'}}>Only</strong> the Gmail contacts you select will receive your AutoGive invitation.</h5>
          <img src={googleContactsSvg} alt="Invite Contacts" className="centered-svg" />
          <TOSLinks/>
          <Button type="button" onClick={handleSignIn} text="View & Select Contacts" extraClass="google-btn" /> 
          <br/>
          <p style={{textAlign: 'center'}}><strong style={{textDecoration: 'underline'}}>Only</strong> the contacts you select will receive your invitation to your AutoGive group.</p>*/}
        </section>        

      ) : (
        <div className="load-contacts-gmail">
          <Form onSubmit={() => { }}>
            <div className="invite-contacts-header">
              <Heading>Invite Contacts</Heading>
              <h5>Search your address book and add <strong>{!invitesSent && <span>at least 3</span>} contacts</strong> to invite.</h5>            
              <div className="to-field d-flex pd-lfrt--lg">
               <span>⌕</span>
               {/*<TextArea placeholder="Search by name or email..." binding={inputValue} onChange={handleInputChange} onFocus={addListComma} noScrollHeight />*/}
               <TextArea placeholder="Search by name or email..." binding={inputValue} onChange={handleInputChange} noScrollHeight />               
              </div>
            </div>

            <div className="contacts-list one hide">
              {contactListWithLetterHeaders()}
              {errorMessage && <P classes="error-message">{errorMessage}</P>}
            </div>
            <div className="contacts-list two">
              <div className="selected-contacts">Selected Contacts: <strong>{selectedDivs.length}</strong></div>
              {selectedContactsList()}
              {!selectedDivs.length && <img src={greyContactSvg} alt="Invite Contacts" className="centered-svg mg-top--xs" />}
              {errorMessage && <P classes="error-message">{errorMessage}</P>}
            </div>
                          
            <div className="sticky">
              <Button extraClass="mg-top--lg" type="button" onClick={handleInviteClick} disabled={selectedContacts.length < threshold} text="Invite Contacts" />
              {!invitesSent && <>
                {selectedContacts.length < 3 ? (
                  <P classes="text-center font-semibold">Please add at least 3 contacts</P>
                ) : (
                  <P classes="text-center font-semibold success-purple">You're ready to invite!</P>
                )}
              </>}
            </div>
          </Form>
        </div>
      )}
    </BaseLayout>
  );
};

export default mustBeSignedIn(groupIdInUrl(LoadContactsGmail));
