import React from 'react'
import { Anchor, P } from 'components'

import './index.scss'

const TOSLinks: React.FC = () => {
  return (
    <P classes="lh-1 mg-btm--lg" centered>
      <span className="TOSLinks">
        I agree to all of AutoGive’s{' '}<br/>
        <Anchor url="https://www.autogive.com/terms" openInNewTab trusted>
          <strong>terms</strong>
        </Anchor>
        ,{' '}
        <Anchor url="https://www.autogive.com/terms#fees" openInNewTab trusted>
          <strong>fees</strong>
        </Anchor>
         {' '}and{' '}
        <Anchor url="https://www.autogive.com/terms#privacy-policy" openInNewTab trusted>
          <strong>privacy policy</strong>
        </Anchor>
        .
      </span>
    </P>
  )
}

export default TOSLinks
