import React, { useEffect, useState } from 'react'
import BaseLayout from 'blocks/layout/base-layout'
import {
  Link,
  Form,
  Heading,
  Section,
  Icon
} from 'components'
import { postData } from 'helpers/post-data'
import { useApiQuery } from 'hooks'
import { builder, mustBeSignedIn } from 'routes'
import hifiveIcon from 'assets/images/hi-five.svg'
import { showConfetti } from 'helpers'

import { useParams } from 'react-router-dom'

const GoogleViewGroup: React.FC = () => {
  showConfetti()
  const groupIds = useApiQuery('/api/group/mine', undefined)
  const newGroupId = groupIds?.slice(-1)
  const trackEvent = (n: string) => {
    postData('/api/analytics/track', { funnel: "GROUP_CREATE", event: n, url: window.location.href })
    return false
  }

  useEffect(() => { trackEvent('APP_GC_YOU_DID_IT') }, [])

  return (
    <BaseLayout>
      <Section id="GoogleSuccess">
        <Heading>You Did It!</Heading>
        <h5>You’re ready to view your AutoGive group & invite donors!</h5>
        <img src={hifiveIcon} alt="View Groups" className="centered-svg" />
        {!!newGroupId && <Link to={builder.group.view(newGroupId[0])} button>View My Group</Link>}
      </Section>
    </BaseLayout>
  )
}

export default mustBeSignedIn(GoogleViewGroup)
